import React from 'react';
import PropTypes from 'prop-types';

const Page = ({ header, breadcrumb, content, className }) => {
  return (
    <main className="main">
      {header}
      {breadcrumb && (
        <nav className="breadcrumb-nav">{breadcrumb}</nav>
      )}
      <div className={`page-content ${className}`}>{content}</div>
    </main>
  );
};

Page.propTypes = {
  header: PropTypes.node,
  breadcrumb: PropTypes.node,
  content: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Page.defaultProps = {
  header: null,
};

export default Page;
