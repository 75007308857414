import {
  Button,
  Container,
  Image,
  Link,
  Page,
  TextBalancer,
} from 'components/common';
import HeaderSEO from 'components/HeaderSEO';

export default function NotFound() {
  // Opinionated: do not record an exception in error/crash analytics e.g. Sentry for 404
  return (
    <Page
      header={
        <>
          <HeaderSEO
            title="Page not found | Yummy Bros"
            preventIndex
          />
        </>
      }
      content={
        <Container>
          <div className="tw-py-16 md:tw-py-20 tw-grid md:tw-grid-cols-2 tw-gap-y-12 lg:tw-px-12 xl:tw-px-36">
            <div className="tw-flex tw-justify-center tw-mt-4">
              <Image
                src="/assets/images/muscle-man.png"
                alt="Muscle man"
                width={475}
                height={534}
                layout="intrinsic"
                priority
              />
            </div>
            <div className="tw-flex tw-flex-col tw-justify-center">
              <TextBalancer
                as="h1"
                className="tw-text-[4rem] md:tw-text-[5rem] lg:tw-text-8xl tw-font-black tw-text-center"
              >
                <span className="tw-block tw-leading-tight">
                  Hey Beautiful,
                </span>
                <span className="tw-block tw-leading-tight">
                  You look lost!
                </span>
              </TextBalancer>
              <TextBalancer className="tw-text-2xl lg:tw-text-3xl tw-mb-4 tw-text-center">
                Don't worry. I'll help you get back to ordering
                yummilicious meal preps.
              </TextBalancer>
              <TextBalancer className="tw-italic tw-text-xl lg:tw-text-2xl tw-text-center">
                PS: I'm not included in every order of Yummy Bros.
              </TextBalancer>

              <div className="tw-flex tw-justify-center tw-items-center">
                <Link to="/" className="tw-mt-8">
                  <Button
                    type="button"
                    className="tw-uppercase tw-py-5 md:tw-py-6"
                  >
                    <span>OOOH YEA SAVE ME, PLEASE!</span>
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </Container>
      }
    />
  );
}
